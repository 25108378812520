import { ref } from 'vue';

export function useCountdown(options: Readonly<{
  defaultText?: string;
  resetText?: string;
  countingText?: string;
  duration?: 60;
}> = {}) {
  const {
    defaultText = '发送验证码',
    resetText = '重新发送',
    countingText = '({num})',
    duration = 60,
  } = options;

  const currentText = ref(defaultText);
  const counting = ref(false);

  let currentDuration = 0;
  let countId = 0;

  const clear = () => {
    clearInterval(countId);
    currentDuration = 0;
    counting.value = false;
  };

  const start = () => {
    currentDuration = duration;
    currentText.value = countingText.replace('{num}', currentDuration.toString());
    counting.value = true;

    countId = setInterval(() => {
      currentDuration--;
      currentText.value = countingText.replace('{num}', currentDuration.toString());

      if (currentDuration === 0) {
        clear();
        currentText.value = resetText;
      }
    }, 1000);
  };

  return {
    counting,
    currentText,
    start,
    clear,
  };
}
